import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/pages/login',
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: () => import('../views/dashboard/Dashboard.vue'),
    meta: {
      title: 'Dashboard',
      requiresAuth: true,
    },
  },
  {
    path: '/new-membership',
    name: 'new-membership',
    component: () => import('../views/user_interface/register/NewMembership'),
    meta: {
      title: 'New Membership',
      requiresAuth: true,
    },
  },
  {
    path: '/new-accomplishment',
    name: 'new-accomplishment',
    component: () => import('../views/user_interface/register/NewAccomplishment'),
    meta: {
      title: 'New Accomplishment',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/new-monthly-meeting',
    name: 'new-monthly-meeting',
    component: () => import('../views/user_interface/register/NewMonthlyMeeting'),
    meta: {
      title: 'New Monthly Meeting',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/accomplishment-report-per',
    name: 'accomplishment-report-per',
    component: () => import('../views/reports_interface/ListOfAccomplishmentPerDistrict'),
    meta: {
      title: 'List Of Accomplishment',
      requiresAuth: true,
    },
  },
  {
    path: '/accomplishment-pointing-report-per',
    name: 'accomplishment-pointing-report-per',
    component: () => import('../views/reports_interface/PointingSystemPerDistrict'),
    meta: {
      title: 'Accomplishment Pointing System Report',
      requiresAuth: true,
    },
  },
  {
    path: '/monthly-meeting-report-per',
    name: 'monthly-meeting-report-per',
    component: () => import('../views/reports_interface/ListOfMonthlyMeetingPerDistrict'),
    meta: {
      title: 'List Of Monthly Meeting Per District',
      requiresAuth: true,
    },
  },
  {
    path: '/cash-flow',
    name: 'cash-flow',
    component: () => import('../views/admin_interface/CashFlow'),
    meta: {
      title: 'Cash Flow',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-bank-depositories',
    name: 'new-bank-depositories',
    component: () => import('../views/admin_interface/BankDepositories'),
    meta: {
      title: 'New Bank Depositories',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-branch-details',
    name: 'new-branch-details',
    component: () => import('../views/admin_interface/NewDistrict'),
    meta: {
      title: 'New District Details',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-deposit-slip',
    name: 'new-deposit-slip',
    component: () => import('../views/load_interface/DepositSlips'),
    meta: {
      title: 'New Deposit Slip',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/payments',
    name: 'payments',
    component: () => import('../views/payments/Payments'),
    meta: {
      title: 'Payments',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/new-user',
    name: 'new-user',
    component: () => import('../views/admin_interface/NewUser'),
    meta: {
      title: 'New User',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/search-members',
    name: 'search-members',
    component: () => import('../views/search_interface/SearchMembers'),
    meta: {
      title: 'Search Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-bank-depositories',
    name: 'list-of-bank-depositories',
    component: () => import('../views/view_interface/ListOfBankDepository'),
    meta: {
      title: 'List Of Bank Depository',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/remittances',
    name: 'remittances',
    component: () => import('../views/view_interface/Remittances'),
    meta: {
      title: 'Remittances',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-new-members',
    name: 'list-of-new-members',
    component: () => import('../views/reports_interface/ListOfNewMember'),
    meta: {
      title: 'List of New Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-members-by-district',
    name: 'list-of-members-by-district',
    component: () => import('../views/reports_interface/ListOfCurrentOfficerByDistrict'),
    meta: {
      title: 'List of Members By District',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-info',
    name: 'list-of-info',
    component: () => import('../views/reports_interface/ListOfFoundation'),
    meta: {
      title: 'List of Foundation',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-clubs',
    name: 'list-of-clubs',
    component: () => import('../views/reports_interface/ListOfClubs'),
    meta: {
      title: 'List of Clubs',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-districts',
    name: 'list-of-districts',
    component: () => import('../views/reports_interface/ListOfDistricts'),
    meta: {
      title: 'List of Districts',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/new-inventory-particulars',
    name: 'new-inventory-particulars',
    component: () => import('../views/admin_interface/NewInventoryParticulars'),
    meta: {
      title: 'New Inventory Particulars',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/load-inventories',
    name: 'load-inventories',
    component: () => import('../views/admin_interface/LoadInventory'),
    meta: {
      title: 'Load Inventory',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/assign-inventories',
    name: 'assign-inventories',
    component: () => import('../views/admin_interface/AssignInventory'),
    meta: {
      title: 'Assign Inventory',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-floating-members',
    name: 'list-of-floating-members',
    component: () => import('../views/reports_interface/ListOfFloatingMembers'),
    meta: {
      title: 'List Of Floating Members',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },

  {
    path: '/list-of-due-members',
    name: 'list-of-due-members',
    component: () => import('../views/reports_interface/ListOfDueMembers'),
    meta: {
      title: 'List Of Due Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-paid-due-members',
    name: 'list-of-paid-due-members',
    component: () => import('../views/reports_interface/ListOfPaidDueMembers'),
    meta: {
      title: 'List Of Paid Due Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-unpaid-members',
    name: 'list-of-unpaid-members',
    component: () => import('../views/reports_interface/ListOfUnpaidMembers'),
    meta: {
      title: 'List Of Unpaid Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-paid-members',
    name: 'list-of-paid-members',
    component: () => import('../views/reports_interface/ListOfPaidMembers'),
    meta: {
      title: 'List Of Paid Members',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-verified-members-by-merchant',
    name: 'list-of-verified-members-by-merchant',
    component: () => import('../views/reports_interface/ListOfVerfiedByMerchant'),
    meta: {
      title: 'List Of Verified By Merchant',
      requiresAuth: true,
      requiresMerchant: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/pages/login',
    name: 'pages-login',
    component: () => import('../views/pages/Login.vue'),
    meta: {
      layout: 'blank',
      title: 'Login',
      requiresVisitor: true,
    },
  },
  {
    path: '/pages/register',
    name: 'pages-register',
    component: () => import('../views/pages/Register.vue'),
    meta: {
      layout: 'blank',
      title: 'Register',
      requiresAuth: true,
    },
  },
  {
    path: '/error-404',
    name: 'error-404',
    component: () => import('../views/Error.vue'),
    meta: {
      layout: 'blank',
      title: '404|Not Found',
    },
  },

  {
    path: '*',
    redirect: 'error-404',
  },

  {
    path: '/new-partnership',
    name: 'new-partnership',
    component: () => import('../views/user_interface/register/NewPartnership'),
    meta: {
      title: 'New Partnership',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/new-request',
    name: 'new-request',
    component: () => import('../views/user_interface/Request'),
    meta: {
      title: 'New Request',
      requiresAuth: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/list-of-partners',
    name: 'list-of-partners',
    component: () => import('../views/reports_interface/ListOfPartners'),
    meta: {
      title: 'List Of Partners',
      requiresAuth: true,
      requiresAdmin: true,
      requiresBranchManager: true,
    },
  },
  {
    path: '/create-voucher',
    name: 'create-voucher',
    component: () => import('../views/admin_interface/CashVoucher'),
    meta: {
      title: 'Cash Voucher',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/cash-voucher-report',
    name: 'cash-voucher-report',
    component: () => import('../views/admin_interface/CashVoucherReport'),
    meta: {
      title: 'Cash Voucher Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/request-not-yet-released',
    name: 'request-not-yet-released',
    component: () => import('../views/admin_interface/ListOfRequestNotYetReleased'),
    meta: {
      title: 'List of Request Not Yet Released Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/assign-appointees',
    name: 'assign-appointees',
    component: () => import('../views/admin_interface/AssignAppointees'),
    meta: {
      title: 'Assign Appointees',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/inventory-report',
    name: 'inventory-report',
    component: () => import('../views/admin_interface/InventoryReport'),
    meta: {
      title: 'Inventory Report',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/register-inductions',
    name: 'register-inductions',
    component: () => import('../views/user_interface/register/NewInductions'),
    meta: {
      title: 'New Inductions',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/register-tickets',
    name: 'register-tickets',
    component: () => import('../views/user_interface/register/NewTickets'),
    meta: {
      title: 'New Tickets',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-inducted',
    name: 'list-of-inducted',
    component: () => import('../views/reports_interface/ListOfInducted'),
    meta: {
      title: 'List Of Inducted',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/list-of-tickets',
    name: 'list-of-tickets',
    component: () => import('../views/reports_interface/ListOfTickets'),
    meta: {
      title: 'List Of Tickets',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/monthly-collection-of-inducted',
    name: 'monthly-collection-of-inducted',
    component: () => import('../views/reports_interface/MonthlySummaryCollectionInducted'),
    meta: {
      title: 'Monthly Collection Of Inducted',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/monthly-collection-of-tickets',
    name: 'monthly-collection-of-tickets',
    component: () => import('../views/reports_interface/MonthlySummaryCollectionTickets'),
    meta: {
      title: 'Monthly Collection Of Tickets',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
  {
    path: '/bank-tally',
    name: 'bank-tally',
    component: () => import('../views/admin_interface/BankTally'),
    meta: {
      title: 'Bank Tally',
      requiresAuth: true,
      requiresAdmin: true,
    },
  },
]

const router = new VueRouter({
  routes
})

export default router
